.dataset-viewer {
    flex: 1;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
}

.dataset-viewer > .no-data {
    font-size: 30px;
    align-self: center;
    margin: auto;
}

.separator {
    height: 4px;
    width: 4px;
    flex-shrink: 0;
}

.image {
    flex: 1;
}

.images {
    display: flex;
    flex-direction: column;
}

.camera-parameters {
    margin-top: 8px;
    font-weight: bold;
}

.image-16-9 {
    width: 100%;
    padding-top: 56.67%;
    min-height: auto;
}

@media (min-width: 1024px) {
    .dataset-viewer {
        padding: 8px 0px;
        margin: 0;
    }

    .telemetry-session-data {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
    }

    .separator {
        height: 8px;
        width: 8px;
    }

    .images {
        flex-direction: row;
    }
}
