@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@-ms-viewport {
    width: device-width;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga';
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html,
body,
#root,
.app {
    height: 100%;
}


body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


a {
    color: black;
    text-decoration: none;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.app {
    overflow: hidden;
}

.app {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.app-block {
    height: 100%;
    padding: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-y: auto;
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@media (min-width: 1024px) {
    .app-block {
        padding: 8px;
    }
}

.navigation-bar {
    background-color: #f0f0f0;
    text-align: left;
}

.navigation-bar > a {
    display: inline-block;
    padding: 8px 16px;
    text-decoration: none;
    font-weight: 600;
}

.navigation-bar > a:hover {
    background-color: #c0c0c0;
    color: #5a5a5a;
}
.status-bar {
    background-color: #f0f0f0;
    padding: 8px 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.right-align {
    margin-left: auto;
    margin-right: 0;
}
.react-notice {
    /*
    margin-right: 0;
    margin-left: auto;
    */
}

.react-logo {
    height: 20px;
    pointer-events: none;
    vertical-align: bottom;
}

@media (prefers-reduced-motion: no-preference) {
    .react-logo {
        -webkit-animation: App-logo-spin infinite 10s linear;
                animation: App-logo-spin infinite 10s linear;
    }
}

.dataset-navigator {
    padding: 8px;
    background-color: #f0f0f0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.entry {
    display: block;
    text-align: left;
    padding: 4px 0px;
    vertical-align: middle;
}


.button {
    line-height: 32px;
    font-size: 32px;
    vertical-align: middle;
    cursor: pointer;
}

.button:hover {
    background-color: #c0c0c0;
    color: #5a5a5a;
}

.button.double {
    width: 32px;
    letter-spacing: -23px;
    text-indent: -23px;
}

.current-title {
    font-size: 11px;
}

.current {
    margin: 0px 8px;
    vertical-align: middle;
    font-size: 30px;
}

.disabled {
    color: #c0c0c0;
    pointer-events: none;
    cursor: default;
}

@media (min-width: 1024px) {
    .dataset-navigator {
        margin: 0px 20%;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
    }

    .buttons {
        margin-left: auto;
        margin-right: 0;
    }
}
.session-list {
    /* nothing yet */
}

.session-list-icon {
    font-size: 19px;
    vertical-align: bottom;
    cursor: pointer;
}

.session-list-icon:hover {
    background-color: #c0c0c0;
    color: #5a5a5a;
}

.session-list-list {
    position: absolute;
    margin-top: 4px;
    background-color: #fff;
    padding: 4px 4px;
    display: none;
    border: 1px solid #f0f0f0;
    z-index: 1;
}

.session-list-list ul {
    list-style-type: none;
    padding: 0;
}

.session-list-list.visible {
    display: block;
}

.session-list-list-title {
    display: block;
    background-color: #f0f0f0;
    padding: 4px 16px;
    font-weight: 600;
}

.session-list-list-item {
    padding: 6px 8px;
    display: block;
    cursor: pointer;
    text-align: center;
}

.session-list-list-item:hover {
    background-color: #c0c0c0;
    color: #5a5a5a;
}

.session-list-list-item.no-items {
    pointer-events: none;
    cursor: default;
}
.dataset-viewer-panel {
    background-color: #f0f0f0;
    border: 1px solid #c0c0c0;
    padding: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.dataset-viewer-panel .title {
    font-weight: 600;
    margin-bottom: 8px;
}

.panel-items {
}

.panel-item {
    display: block;
    text-align: left;
    margin-right: 8px;
    margin-bottom: 2px;
}

.panel-image-item {
    display: block;
    position: relative;
    min-height: 100%;
}

.dataset-viewer-panel.image .panel-items {
    -webkit-flex: 2 1;
            flex: 2 1;
}

.panel-image-item .panel-backdrop {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
}

.panel-image-item .panel-backdrop.loading {
    display: block;
    z-index: 10;
}

.panel-image-item .panel-backdrop .loading-image {
    margin: auto;
    height: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.panel-label {

}

.panel-value {

}

.panel-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.panel-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.dataset-viewer {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-top: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.dataset-viewer > .no-data {
    font-size: 30px;
    -webkit-align-self: center;
            align-self: center;
    margin: auto;
}

.separator {
    height: 4px;
    width: 4px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.image {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.images {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.camera-parameters {
    margin-top: 8px;
    font-weight: bold;
}

.image-16-9 {
    width: 100%;
    padding-top: 56.67%;
    min-height: auto;
}

@media (min-width: 1024px) {
    .dataset-viewer {
        padding: 8px 0px;
        margin: 0;
    }

    .telemetry-session-data {
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .separator {
        height: 8px;
        width: 8px;
    }

    .images {
        -webkit-flex-direction: row;
                flex-direction: row;
    }
}

.zoomable-image {
    overflow: hidden;
    touch-action: none;
    position: absolute;
    top: 0; bottom: 0; right: 0; left: 0;
}

.zoomable-image > img {
    width: 100%;
    cursor: crosshair;
    touch-action: none;
}

.zoom-value {
    position: absolute;
    top: 4px;
    right: 4px;
    font-weight: bolder;
    color: #fff;
    pointer-events: none;
    z-index: 2;
}

.about-root {
    margin: auto auto;
    max-width: 900px;
}

.about-root h1 {
    margin-bottom: 10px;
}
.about-root h2 {
    margin: 0px auto 20px;
    font-weight: normal;
    font-size: 16px;
}

.about-root p {
    text-align: justify;
}

.about-root p.centered {
    text-align: center;
}

.about-root a {
    color: #0c0c0c;
    font-weight: bold;
}
.intro-text {
    text-align: left;
    padding: 8px;
}

.intro-text a {
    font-weight: bold;
}

.thumbnail {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.file-link {
    text-align: left;
    font-weight: bold;
}

@media (min-width: 768px) {
    .thumbnail {
        max-height: 100%;
        width: auto;
    }

    .file-panel {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .file-thumbnail {
        margin-right: 8px;
    }

    .file-data-panel {
    }
}
