.intro-text {
    text-align: left;
    padding: 8px;
}

.intro-text a {
    font-weight: bold;
}

.thumbnail {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.file-link {
    text-align: left;
    font-weight: bold;
}

@media (min-width: 768px) {
    .thumbnail {
        max-height: 100%;
        width: auto;
    }

    .file-panel {
        flex-direction: row;
        align-items: flex-start;
    }

    .file-thumbnail {
        margin-right: 8px;
    }

    .file-data-panel {
    }
}