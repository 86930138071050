.dataset-navigator {
    padding: 8px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
}

.entry {
    display: block;
    text-align: left;
    padding: 4px 0px;
    vertical-align: middle;
}


.button {
    line-height: 32px;
    font-size: 32px;
    vertical-align: middle;
    cursor: pointer;
}

.button:hover {
    background-color: #c0c0c0;
    color: #5a5a5a;
}

.button.double {
    width: 32px;
    letter-spacing: -23px;
    text-indent: -23px;
}

.current-title {
    font-size: 11px;
}

.current {
    margin: 0px 8px;
    vertical-align: middle;
    font-size: 30px;
}

.disabled {
    color: #c0c0c0;
    pointer-events: none;
    cursor: default;
}

@media (min-width: 1024px) {
    .dataset-navigator {
        margin: 0px 20%;
        flex-direction: row;
        align-items: center;
    }

    .buttons {
        margin-left: auto;
        margin-right: 0;
    }
}