.navigation-bar {
    background-color: #f0f0f0;
    text-align: left;
}

.navigation-bar > a {
    display: inline-block;
    padding: 8px 16px;
    text-decoration: none;
    font-weight: 600;
}

.navigation-bar > a:hover {
    background-color: #c0c0c0;
    color: #5a5a5a;
}