.zoomable-image {
    overflow: hidden;
    touch-action: none;
    position: absolute;
    top: 0; bottom: 0; right: 0; left: 0;
}

.zoomable-image > img {
    width: 100%;
    cursor: crosshair;
    touch-action: none;
}

.zoom-value {
    position: absolute;
    top: 4px;
    right: 4px;
    font-weight: bolder;
    color: #fff;
    pointer-events: none;
    z-index: 2;
}
